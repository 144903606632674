import React, { FC } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { TS } from '-/tagspaces.namespace';

type ColumnTitleProps = {
  directory: TS.FileSystemEntry;
  dragHandleProps: any;
  isCurrentDir: boolean;
  handleGridCellDblClick: (event: Object, fsEntry: TS.FileSystemEntry) => void;
  handleGridContextMenu: (event: Object, fsEntry: TS.FileSystemEntry) => void;
};

const ColumnTitle: FC<ColumnTitleProps> = ({
  dragHandleProps,
  directory,
  handleGridCellDblClick,
  handleGridContextMenu,
  isCurrentDir
}) => {
  return (
    <Tooltip
      title={isCurrentDir ? 'Current folder' : 'Subfolder'}
      placement="top"
      arrow
    >
      <div
        onContextMenu={event => handleGridContextMenu(event, directory)}
        onDoubleClick={event => {
          if (!isCurrentDir) {
            handleGridCellDblClick(event, directory);
          }
        }}
        style={{
          position: 'relative',
          cursor: 'pointer',
          padding: '10px 8px',
          textOverflow: 'ellipsis',
          display: 'block',
          overflow: 'hidden',
          minHeight: '30px'
          // backgroundColor: 'white'
        }}
        {...dragHandleProps}
      >
        {directory.name}
      </div>
    </Tooltip>
  );
};
export default ColumnTitle;
