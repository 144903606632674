import versionMeta from '-/version.json';
import { formatDateTime4Tag } from '-/utils/misc';
import { saveAsTextFile } from '-/services/utils-io';
import CryptoJS from 'crypto-js';
import { TS } from '-/tagspaces.namespace';

export function exportLocations(
  locations: Array<TS.Location>,
  aesPassword: string = undefined
) {
  const locationForExport = [];
  locations.forEach(location => {
    locationForExport.push(prepareLocationForExport(location));
  });
  const exports = {
    appName: versionMeta.name,
    appVersion: versionMeta.version,
    locations: locations
  };
  let blob;
  let filename;
  const dateTimeTag = formatDateTime4Tag(new Date(), true);
  if (aesPassword) {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(exports),
      aesPassword
    ).toString();

    blob = new Blob([ciphertext], {
      type: 'multipart/encrypted'
    });
    // tsec - tagspaces encrypted config
    filename = 'locations [tagspaces ' + dateTimeTag + '].tsec';
  } else {
    blob = new Blob([JSON.stringify(exports, null, 2)], {
      type: 'application/json'
    });
    filename = 'locations [tagspaces ' + dateTimeTag + '].json';
  }
  saveAsTextFile(blob, filename);
  console.log('Locations exported...');
}

export function prepareLocationForExport(location: TS.Location): TS.Location {
  const preparedLocation: TS.Location = {
    isDefault: location.isDefault,
    name: location.name,
    uuid: location.uuid,
    type: location.type
  };
  if (location.endpointURL) {
    preparedLocation.endpointURL = location.endpointURL;
  }
  if (location.accessKeyId) {
    preparedLocation.accessKeyId = location.accessKeyId;
  }
  if (location.endpointURL) {
    preparedLocation.endpointURL = location.endpointURL;
  }
  if (location.secretAccessKey) {
    preparedLocation.secretAccessKey = location.secretAccessKey;
  }
  if (location.bucketName) {
    preparedLocation.bucketName = location.bucketName;
  }
  if (location.region) {
    preparedLocation.region = location.region;
  }
  if (location.hasOwnProperty('isDefault')) {
    preparedLocation.isDefault = location.isDefault;
  }
  if (location.hasOwnProperty('isReadOnly')) {
    preparedLocation.isReadOnly = location.isReadOnly;
  }
  if (location.hasOwnProperty('disableIndexing')) {
    preparedLocation.disableIndexing = location.disableIndexing;
  }
  if (location.hasOwnProperty('fullTextIndex')) {
    preparedLocation.fullTextIndex = location.fullTextIndex;
  }
  if (location.hasOwnProperty('watchForChanges')) {
    preparedLocation.watchForChanges = location.watchForChanges;
  }
  if (location.hasOwnProperty('persistTagsInSidecarFile')) {
    preparedLocation.persistTagsInSidecarFile =
      location.persistTagsInSidecarFile;
  }
  if (location.creationDate) {
    preparedLocation.creationDate = location.creationDate;
  }
  if (location.ignorePatternPaths) {
    preparedLocation.ignorePatternPaths = location.ignorePatternPaths;
  }
  if (location.path) {
    preparedLocation.path = location.path;
  }
  if (location.maxIndexAge) {
    preparedLocation.maxIndexAge = location.maxIndexAge;
  }
  return preparedLocation;
}

export function importLocations(ciphertext: string, aesPassword: string) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, aesPassword);
  const jsonObj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  if (jsonObj && jsonObj.locations) {
    return jsonObj.locations;
  }
  return undefined;
}
