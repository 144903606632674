import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import {
  enhanceDirectoryContent,
  loadMetaDataPromise, orderByMetaArray, orderDirectories
} from "-/services/utils-io";
import PlatformIO from '-/services/platform-facade';
import Column from './Column';
import { MetaOperations } from '../../../../index';
import {
  //  extractContainingDirectoryPath,
  extractDirectoryName,
  extractFileName
} from '-/utils/paths';
import { OpenedEntry } from '-/reducers/app';
import AppConfig from '-/config';
import { TS } from '-/tagspaces.namespace';
import { locationType } from '-/utils/misc';

type KanBanDirsDnDProps = {
  ref?: React.RefObject<Handles>;
  directories: Array<TS.FileSystemEntry>;
  folderContent: Array<TS.FileSystemEntry>;
  showDirectories: boolean;
  renderCell: (file: TS.FileSystemEntry, index: number) => void;
  entrySize: string;
  sortBy: string;
  orderBy: boolean;
  currentLocationPath: string;
  currentLocationType: string;
  filesLimit: number;
  pageLimit: number;
  pageOffset: number;
  moveFiles: (files: Array<string>, destination: string) => any;
  handleGridCellDblClick: (event: Object, fsEntry: TS.FileSystemEntry) => void;
  handleGridContextMenu: (event: Object, fsEntry: TS.FileSystemEntry) => void;
  openedFiles: Array<OpenedEntry>;
  currentDirectoryColor: string;
  currentDirectoryPath: string;
  metaDirs: Array<any>;
};

export interface Handles {
  reloadFolders: (paths: Array<string>) => void;
}

// @ts-ignore
const KanBanDirsDnD: FC<KanBanDirsDnDProps> = forwardRef(
  (props: KanBanDirsDnDProps, ref: React.Ref<Handles>) => {
    const {
      directories,
      folderContent,
      showDirectories,
      renderCell,
      sortBy,
      currentLocationPath,
      currentLocationType,
      filesLimit,
      entrySize,
      pageLimit,
      pageOffset,
      openedFiles
    } = props;
    const [files, setFiles] = useState(undefined);

    useImperativeHandle(ref, () => ({
      reloadFolders(paths: Array<string>) {
        reloadColumns(paths);
      }
    }));

    /* useEffect(() => {
      if (openedFiles.length > 0) {
        const dirPaths = openedFiles.map(openedFile => {
          if (openedFile.shouldReload) {
            return extractContainingDirectoryPath(
              openedFile.path,
              PlatformIO.getDirSeparator()
            );
          }
          return undefined;
        });

        reloadColumns(dirPaths);
      }
    }, [openedFiles]); */

    useEffect(() => {
      setFiles(undefined);

      if (directories.length > 0) {
        // const dirContent = [];

        let limitedDirectories = directories.slice(
          pageOffset,
          pageLimit + pageOffset
        );
        if(props.metaDirs) {
          limitedDirectories = orderDirectories(limitedDirectories, props.metaDirs)
        }
        const promises = limitedDirectories.map((entry: TS.FileSystemEntry) => {
          // TODO get metadata and set Dir color
          return getDirContent(entry.path).then(directoryContent => ({[entry.path]: directoryContent })
            // dirContent.push({ [entry.path]: directoryContent })
          );
        });

        Promise.all(promises).then((dirContent) => {
          //check for Empty object
          if (Object.keys(dirContent).length > 0) {
            // orderDirectories(dirContent, props.metaDirs).then(dirs => {
              if (folderContent && folderContent.length > 0) {
                dirContent.unshift({
                  [props.currentDirectoryPath]: filesLimit
                    ? folderContent.slice(0, filesLimit)
                    : folderContent
                });
              }
              setFiles(dirContent);
            // });
          }
        });
      } else if (folderContent && folderContent.length > 0) {
        setFiles([
          {
            [props.currentDirectoryPath]: filesLimit
              ? folderContent.slice(0, filesLimit)
              : folderContent
          }
        ]);
      }
    }, [directories, folderContent]);

    async function getDirContent(path) {
      const results = await PlatformIO.listDirectoryPromise(path, [
        'extractThumbURL',
        'extractThumbPath'
      ]);
      if (results === undefined) {
        return [];
      }
      const isCloudLocation = currentLocationType === locationType.TYPE_CLOUD;

      let {
        directoryContent,
        tmbGenerationPromises,
        tmbGenerationList
      } = enhanceDirectoryContent(
        results,
        isCloudLocation,
        false, // settings.showUnixHiddenEntries,
        false, // settings.useGenerateThumbnails
        showDirectories,
        filesLimit
      );

      // reorder files
      //if (sortBy === 'custom') {
      try {
        const metaFilesData = await loadMetaDataPromise(path);
        if (metaFilesData && metaFilesData.files.length > 0) {
          // reorder by filename
          return orderByMetaArray(directoryContent, metaFilesData.files);
        }
      } catch (e) {
        console.log('error loadMetaDataPromise for:' + path, e);
      }
      //}

      return directoryContent;
    }

    /**
     * reorder Column position
     * @param initialPosition
     * @param finalPosition
     */
    const reorderColumnPosition = (initialPosition, finalPosition) => {
      const reorder = (
        list: any,
        startIndex: number,
        endIndex: number
      ): any => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
      };

      const newItems = reorder(files, initialPosition, finalPosition);
      setFiles(newItems);

      const dirEntries = newItems.map(entry => {
        const path = Object.keys(entry)[0];
        return directories.find(element => element.path === path);
      });
      // save to meta directory json
      MetaOperations.saveDirectoryOrder(props.currentDirectoryPath, dirEntries)
        .then(entryMeta => {
          console.log(entryMeta);
          return true;
        })
        .catch(error => {
          console.warn('Error saving description ' + error);
        });
    };

    /*const insert = (
    path: string,
    list: any,
    index: number,
    newItem: object
  ): any => {
    const result = [...list[path]];
    result.splice(index, 0, newItem);
    return { [path]: result };
  };*/

    const reorder = (
      path: string,
      list: any,
      startIndex: number,
      endIndex: number
    ): any => {
      //const index = list.findIndex(element => element.hasOwnProperty(path));
      const result = [...list[path]];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return { [path]: result };
    };

    /**
     * the same folder reorder
     * @param initialPosition
     * @param finalPosition
     * @param path
     */
    const reorderCellPosition = (initialPosition, finalPosition, path) => {
      const index = files.findIndex(element => element.hasOwnProperty(path));
      const newItems = reorder(
        path,
        files[index],
        initialPosition,
        finalPosition
      );
      const newFiles = [];
      files.map((entry, ind) => {
        if (ind === index) {
          newFiles.push(newItems);
        } else {
          newFiles.push(entry);
        }
      });
      setFiles(newFiles);

      // save to meta directory json
      MetaOperations.saveFilesOrder(
        path,
        newItems[path]
          .filter(file => file.isFile)
          .map(file => {
            return {
              name: file.name
            };
          })
      )
        .then(entryMeta => {
          console.log(entryMeta);
          return true;
        })
        .catch(error => {
          console.warn('Error saving description ' + error);
        });
    };

    const setColumnFiles = (arrColumns: Array<object>) => {
      if (files) {
        const paths = arrColumns.map(column => Object.keys(column)[0]);

        const newFiles = [];
        files.map(entry => {
          const entryPath = Object.keys(entry)[0];
          const index = paths.indexOf(entryPath);
          if (index !== -1) {
            newFiles.push({ [paths[index]]: arrColumns[index][paths[index]] });
          } else {
            newFiles.push(entry);
          }
        });
        setFiles(newFiles);
      }
    };

    const reloadColumns = (paths: Array<string>) => {
      const promises = paths
        .filter(path => path !== undefined)
        .map(path => getDirContent(path));
      Promise.all(promises).then(arrDirContent => {
        const directoryContent = [];
        arrDirContent.map((dirContent, index) => {
          directoryContent.push({ [paths[index]]: dirContent });
        });
        setColumnFiles(directoryContent);
      });
    };

    const reorderInDiffColumn = (
      initialPosition,
      finalPosition,
      srcPath,
      destPath
    ) => {
      const srcColumnIndex = files.findIndex(element =>
        element.hasOwnProperty(srcPath)
      );
      const destColumnIndex = files.findIndex(element =>
        element.hasOwnProperty(destPath)
      );

      //remove item
      const arrColumn = [...files[srcColumnIndex][srcPath]];
      const [removed] = arrColumn.splice(initialPosition, 1);
      removed.path = destPath + PlatformIO.getDirSeparator() + removed.name;

      const newFiles = [];
      files.forEach((entry, ind) => {
        if (ind === srcColumnIndex) {
          newFiles.push({ [srcPath]: arrColumn });
          saveToMetaDir(srcPath, arrColumn);
        } else if (ind === destColumnIndex) {
          const newItemsDest = [...entry[destPath]];
          newItemsDest.splice(finalPosition, 0, removed);
          newFiles.push({ [destPath]: newItemsDest });
          saveToMetaDir(destPath, newItemsDest);
        } else {
          newFiles.push(entry);
        }
      });
      setFiles(newFiles);
    };

    const saveToMetaDir = (path, columnItems) => {
      MetaOperations.saveFilesOrder(
        path,
        columnItems
          .filter(file => file.isFile)
          .map(file => {
            return {
              name: file.name
            };
          })
      )
        .then(entryMeta => {
          console.log(entryMeta);
          return true;
        })
        .catch(error => {
          console.warn('Error saving description ' + error);
        });
    };

    const onDragEnd = (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.type === 'COLUMN') {
        reorderColumnPosition(result.source.index, result.destination.index);
        return;
      }

      const sourcePath = result.source.droppableId;
      const destinationPath = result.destination.droppableId;

      if (sourcePath == destinationPath) {
        if (result.destination.index === result.source.index) {
          return;
        }
        // the same folder reorder
        reorderCellPosition(
          result.source.index,
          result.destination.index,
          destinationPath
        );
      } else {
        reorderInDiffColumn(
          result.source.index,
          result.destination.index,
          sourcePath,
          destinationPath
        );
        // move to diff folder
        const fileName = extractFileName(
          result.draggableId,
          PlatformIO.getDirSeparator()
        );

        props
          .moveFiles(
            [sourcePath + PlatformIO.getDirSeparator() + fileName],
            destinationPath
          )
          .then(success => {
            if (success) {
              /*const fileName = extractFileName(
              result.draggableId,
              PlatformIO.getDirSeparator()
            );
            loadMetaDataPromise(destinationPath).then(metaFilesData => {
              const arrReturn = [];
              if (metaFilesData && metaFilesData.files.length > 0) {
                metaFilesData.files.map((file, index) => {
                  if (result.destination.index === index) {
                    arrReturn.push({ name: fileName });
                  }
                  if (file.name !== fileName) {
                    arrReturn.push(file);
                  } else {
                    result.destination.index += 1;
                  }

                  return true;
                });
              } else {
                arrReturn.push({ name: fileName });
              }

              MetaOperations.saveFilesOrder(destinationPath, arrReturn)
                .then(entryMeta => {
                  console.log(entryMeta);

                  reloadColumns([sourcePath, destinationPath]);
                  return true;
                })
                .catch(error => {
                  console.warn('Error saving files ' + error);
                });
            });*/
            }
          });
      }
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="board"
          key="dndBoard"
          type="COLUMN"
          direction="horizontal"
        >
          {provided => (
            <div
              style={{
                // whiteSpace: 'nowrap',
                // @ts-ignore
                overflowX: AppConfig.isFirefox ? 'auto' : 'overlay',
                overflowY: 'hidden',
                height: '100%',
                display: 'flex',
                marginBottom: '8px',
                paddingBottom: '8px'
                // position: 'absolute',
                // top: 0,
                // right: 0,
                // bottom: 0,
                // left: 0
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {files &&
                // directories.length > 0 &&
                files.map((entry, index) => {
                  const path = Object.keys(entry)[0];
                  let isCurrentDir = false;
                  let dirEntry;
                  if (path === props.currentDirectoryPath) {
                    isCurrentDir = true;
                    dirEntry = {
                      name: extractDirectoryName(
                        props.currentDirectoryPath,
                        PlatformIO.getDirSeparator()
                      ),
                      path: props.currentDirectoryPath,
                      color: props.currentDirectoryColor
                    };
                  } else {
                    dirEntry = directories.find(
                      element => element.path === path
                    );
                  }
                  /*const columnFiles = files.find(element =>
                  element.hasOwnProperty(entry.path)
                );*/
                  return (
                    <Column
                      index={index}
                      isCurrentDir={isCurrentDir}
                      directory={dirEntry}
                      renderCell={renderCell}
                      files={entry[path]}
                      setColumnFiles={setColumnFiles}
                      entrySize={entrySize}
                      handleGridCellDblClick={props.handleGridCellDblClick}
                      handleGridContextMenu={props.handleGridContextMenu}
                    />
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

const areEqual = (prevProp: KanBanDirsDnDProps, nextProp: KanBanDirsDnDProps) =>
  nextProp.currentDirectoryPath === prevProp.currentDirectoryPath &&
  nextProp.showDirectories === prevProp.showDirectories &&
  JSON.stringify(nextProp.folderContent) === JSON.stringify(prevProp.folderContent)
  // JSON.stringify(nextProp.directories) === JSON.stringify(prevProp.directories)

export default React.memo(KanBanDirsDnD,areEqual);
