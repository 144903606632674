import { exec } from 'child_process';
import { walkDirectory } from '-/services/utils-io';
import { TS } from '-/tagspaces.namespace';

export function importTags(directoryPath, entryCallback): Promise<any> {
  return walkDirectory(
    directoryPath,
    {
      recursive: true,
      skipMetaFolder: true,
      skipDotHiddenFolder: true,
      skipDotHiddenFiles: true,
      loadMetaDate: false
    },
    entryCallback,
    entryCallback
  );
}

export function readMacOSTags(filename): Promise<TS.Tag[]> {
  // console.log('reading', filename);

  const cmdArr = [
    'mdls',
    '-raw',
    '-name',
    'kMDItemUserTags',
    '"' + filename + '"'
  ];
  const cmd = cmdArr.join(' ');

  return new Promise((resolve, reject) => {
    const foundTags: TS.Tag[] = [];
    exec(cmd, (error, stdout, stderr) => {
      if (error) {
        console.error(error);
        reject(error);
      }
      if (stderr) {
        console.log(stderr);
        reject(stderr);
      }
      if (stdout && stdout !== '(null)') {
        stdout
          .toString()
          .replace(/^\(|\)$/g, '')
          .split(',')
          .map(item => {
            const newTag: TS.Tag = {
              // id: uuidv1(),
              title: item.trim()
            };
            foundTags.push(newTag);
            return newTag;
          });

        resolve(foundTags);
        // console.log('Tags in file "' + filename + '": ' + JSON.stringify(foundTags));
      } else {
        resolve(foundTags);
      }
    });
  });
}
