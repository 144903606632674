import React, { FC } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Progress } from 'aws-sdk/clients/s3';
import ColumnTitle from './ColumnTitle';
import i18n from '-/services/i18n';
import { actions as AppActions } from '-/reducers/app';
import IOActions from '-/reducers/io-actions';
import AppConfig from '-/config';
import { TS } from '-/tagspaces.namespace';

type ColumnProps = {
  directory: TS.FileSystemEntry;
  index: number;
  renderCell: (file: TS.FileSystemEntry, index: number) => void;
  files: Array<TS.FileSystemEntry>;
  setColumnFiles: (arrColumns: Array<object>) => void; // (files: Array<FileSystemEntry>, path: string) => void;
  entrySize: string;
  isCurrentDir: boolean;
  uploadFilesAPI: (
    files: Array<File>,
    destination: string,
    onUploadProgress?: (progress: Progress, response: any) => void
  ) => any;
  onUploadProgress: (progress: Progress, response: any) => void;
  toggleUploadDialog: () => void;
  resetProgress: () => void;
  handleGridCellDblClick: (event: Object, fsEntry: TS.FileSystemEntry) => void;
  handleGridContextMenu: (event: Object, fsEntry: TS.FileSystemEntry) => void;
  // progress: Array<any>;
};

const Column: FC<ColumnProps> = props => {
  const {
    directory,
    renderCell,
    index,
    entrySize,
    files,
    setColumnFiles,
    isCurrentDir
  } = props;
  let fileInput;
  // const [files, setFiles] = useState(props.files);
  if (directory === undefined) {
    return null;
  }

  /* useEffect(() => {
    if (files.length === props.files.length) {
      setFiles(props.files);
    }
  }, [props.files]); */

  /* const firstRender = useRef(true);
  useEffect(() => {
    if (!firstRender.current && progress.length > 0) {
      const objProgress = progress.filter(
        fileProgress =>
          fileProgress.progress === 100 &&
          fileProgress.path.indexOf('/.ts/') === -1
      );
      if (objProgress.length > 0) {
        console.log('file uploaded ' + JSON.stringify(progress));
        // TODO reload files
      }
    }
    firstRender.current = false;
  }, [progress]); */

  let width = '550px';
  if (entrySize === 'small') {
    width = '250px';
  } else if (entrySize === 'normal') {
    width = '400px';
  }

  function handleFileInputChange(selection: any) {
    props.resetProgress();
    props.toggleUploadDialog();
    props
      .uploadFilesAPI(
        Array.from(selection.currentTarget.files),
        directory.path,
        props.onUploadProgress
      )
      .then(fsEntries => {
        if (fsEntries.length > 0) {
          setColumnFiles([{ [directory.path]: [...files, ...fsEntries] }]);
        }
        /* if (promises) {
          //&& promises.length > 0) {
          Promise.all(promises).then(filesProm => {
            const arrFiles: Array<any> = [];
            filesProm.map(file => {
              if (file !== undefined) {
                arrFiles.push(file);
              }
            });
            if (arrFiles.length > 0) {
              setColumnFiles([{ [directory.path]: [...files, ...arrFiles] }]);
            }
            // console.log(JSON.stringify(files));
          });
        } */
      });
  }

  return (
    <Draggable
      // disableInteractiveElementBlocking={!dragBlocking}
      key={directory.name}
      draggableId={directory.name}
      index={index}
    >
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div
            style={{
              borderRadius: '5px',
              width,
              margin: '10px 0 10px 10px',
              backgroundColor: directory.color || '#ebecf0',
              overflow: 'hidden',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '98%',
              border: isCurrentDir ? '1px solid lightgray' : '1px solid gray'
            }}
          >
            <ColumnTitle
              isCurrentDir={isCurrentDir}
              dragHandleProps={provided.dragHandleProps}
              // listId={directory.path}
              directory={directory}
              handleGridCellDblClick={props.handleGridCellDblClick}
              handleGridContextMenu={props.handleGridContextMenu}
              // setDragBlocking={setDragBlocking}
            />

            <Droppable droppableId={directory.path}>
              {(droppableProvided, droppableSnapshot) => (
                <div
                  style={{
                    margin: '0 4px',
                    padding: '0 4px',
                    // @ts-ignore
                    overflowY: AppConfig.isFirefox ? 'auto' : 'overlay',
                    overflowX: 'hidden'
                  }}
                  ref={droppableProvided.innerRef}
                >
                  {files &&
                    files.map((file: TS.FileSystemEntry, index: number) =>
                      renderCell(file, index)
                    )}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
            <div
              style={{
                minHeight: '38px',
                maxHeight: '38px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                onClick={() => {
                  fileInput.click();
                }}
              >
                {i18n.t('core:addFiles')}
              </Button>
              <input
                style={{ display: 'none' }}
                ref={input => {
                  fileInput = input;
                }}
                accept="*"
                type="file"
                multiple
                onChange={handleFileInputChange}
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

/* function mapStateToProps(state) {
  return {
    progress: state.app.progress
  };
} */

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleUploadDialog: AppActions.toggleUploadDialog,
      onUploadProgress: AppActions.onUploadProgress,
      resetProgress: AppActions.resetProgress,
      uploadFilesAPI: IOActions.uploadFilesAPI
    },
    dispatch
  );
}

export default connect(undefined, mapDispatchToProps)(Column);
